import { FeatureFlags, FlagState } from '../components/FeatureFlag';

const OP_FLAGS_PREFIX = 'op_';

export const getExperiments = (flags: Partial<FlagState>): Partial<FlagState> => {
  return Object.fromEntries(
    Object.entries(flags)
      .filter(([key]) => key.startsWith(OP_FLAGS_PREFIX) || key.startsWith(FeatureFlags.HAS_CONFIGCAT_FLAGS))
      .map(([key, value]) => [key, String(value)])
  );
};
