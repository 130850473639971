import { FeatureFlags } from './FeatureFlag.config';

export enum FlagValueBoolWithExcluded {
  true = 'true',
  false = 'false',
  excluded = 'excluded',
}

// USE THIS FOR FUTURE STANDARD A/B EXPERIMENTS
export enum FlagValuesExperiment {
  control = 'control',
  test = 'test',
  excluded = 'excluded',
}

export interface FlagState {
  [FeatureFlags.OP_NEW_COPY]: FlagValueBoolWithExcluded;
  [FeatureFlags.EE_TEST_NON_OP_FLOW]: FlagValueBoolWithExcluded;
  [FeatureFlags.EE_TEST_BUDGET_VALUES]: FlagValuesExperiment;
  [FeatureFlags.OP_CO_CR1_IMPROVEMENTS]: FlagValuesExperiment;
  [FeatureFlags.HAS_CONFIGCAT_FLAGS]: 'true' | 'false';
}

export interface SettingKeyValue {
  settingKey: string;
  settingValue: any;
}
