import { highlightsQuestionsEnum, passengerTypesEnum } from 'const';
import findIndex from 'lodash-es/findIndex';
import { STEP_KEYS } from 'steps';
import { STEPS } from 'stepsConfig';
import { QuestionsType, StepConfigType, StepsConfigType } from 'stepsConfig.types';

import { FrontendAnswerType } from './answerHook';

export type BackendAnswerTypeEnrichedWithQuestion = {
  question: {
    identifier: string;
    text: string | undefined;
  };
  answers: BackendAnswerType[];
};

export type BackendAnswerType = {
  identifier: string;
  value: string | boolean | number | Date;
  text: string;
  is_default?: boolean;
};

// THIS IS NOT _QUESTION_ ANSWER, it's step-to-answer object
export type AnswerInReactContextType = {
  stepId: STEP_KEYS;
  value: FrontendAnswerType;
};

const getExistingAnswer = (allAnswers: AnswerInReactContextType[], stepConfig: StepConfigType) =>
  allAnswers.find(a => a.stepId === stepConfig.id);

export const answersUtils = {
  add: (answers: AnswerInReactContextType[], orderedAnswer: AnswerInReactContextType) => {
    const indexIfAlreadyInArray = findIndex(answers, answer => answer.stepId === orderedAnswer.stepId);

    const isAlreadyInArray = indexIfAlreadyInArray !== -1;
    const orderedAnswersToKeep = isAlreadyInArray ? indexIfAlreadyInArray : answers.length;
    return answers.slice(0, orderedAnswersToKeep).concat(orderedAnswer);
  },

  // TODO: getBudget is used only formatting for GTM, lets find it a better place
  getBudget: (answers: AnswerInReactContextType[]) =>
    (answers.find(({ stepId }) => stepId === STEPS.BUDGET)?.value.budget as number) ?? 0,

  getInitialValues: (stepConfig: StepConfigType & { questions: QuestionsType }) => {
    return Object.keys(stepConfig.questions).reduce(
      (obj, question) => ({
        ...obj,
        [question]: stepConfig.questions[question].initialValue,
      }),
      {}
    );
  },

  getExistingAnswer,

  getExistingOrInitial: (allAnswers: AnswerInReactContextType[], stepConfig: StepConfigType) => {
    const existingAnswers = getExistingAnswer(allAnswers, stepConfig);

    const initialValues = answersUtils.getInitialValues(stepConfig as any);

    return existingAnswers?.value || initialValues;
  },

  isDefault: (answer: BackendAnswerType, stepConfig: StepConfigType) => {
    const { initialValue } = stepConfig.questions![answer.identifier];

    return Array.isArray(initialValue) ? initialValue.includes(answer.value) : initialValue === answer.value;
  },

  getAllAvailableAnswers: (stepConfig: StepConfigType, identifier: string) => {
    return stepConfig.questions![identifier].availableAnswers || [];
  },
};

type BackendAvailableAnswers = Record<'name' | 'identifier' | 'imageUrl', string>;

export const backendToFrontendAvailableAnswers = (availableAnswers: BackendAvailableAnswers[]) =>
  availableAnswers.map(({ name, identifier, imageUrl }) => ({
    value: identifier,
    title: name,
    imageUrl,
  }));

export const createAvailableAnswers = (
  stepsConfig: StepsConfigType,
  step: STEP_KEYS,
  question: any,
  answers: BackendAvailableAnswers[]
) => ({
  ...stepsConfig[step],
  questions: {
    ...stepsConfig[step].questions,
    [question]: {
      ...stepsConfig[step].questions![question],
      availableAnswers: backendToFrontendAvailableAnswers(answers),
    },
  },
});

const findPassengerValueByType = ({ type, answers }: { type: string; answers: BackendAnswerType[] }) =>
  answers.find(({ identifier }) => identifier === type)?.value;

const mapAnswerToValue = ({ stepId, answers }: { stepId: STEP_KEYS; answers: BackendAnswerType[] }) => {
  switch (stepId) {
    case STEPS.PASSENGERS: {
      return {
        [passengerTypesEnum.adults]: findPassengerValueByType({
          type: passengerTypesEnum.adults,
          answers,
        }),
        [passengerTypesEnum.children]: findPassengerValueByType({
          type: passengerTypesEnum.children,
          answers,
        }),
        [passengerTypesEnum.infants]: findPassengerValueByType({
          type: passengerTypesEnum.infants,
          answers,
        }),
      };
    }
    case STEPS.HIGHLIGHTS: {
      return {
        [highlightsQuestionsEnum.poi]: answers.reduce((prev, { identifier, value }) => {
          if (identifier === highlightsQuestionsEnum.poi) {
            prev.push(value);
          }
          return prev;
        }, [] as any),
        [highlightsQuestionsEnum.preferTalkToAgent]: answers.find(
          ({ identifier }) => identifier === highlightsQuestionsEnum.preferTalkToAgent
        )?.value,
      };
    }
    case STEPS.INTENT: {
      return {
        [STEPS.INTENT]: answers.reduce((prev, { identifier, value }) => {
          if (identifier === STEPS.INTENT) {
            prev.push(value);
          }
          return prev;
        }, [] as any),
      };
    }
    default: {
      return {
        [stepId]: answers[0].value,
      };
    }
  }
};

export const getAnswersFromLocalStorage = (answers: BackendAnswerTypeEnrichedWithQuestion[]) => {
  return answers.map(({ question, answers }) => ({
    stepId: question.identifier as STEP_KEYS,
    value: mapAnswerToValue({ stepId: question.identifier as STEP_KEYS, answers }),
  }));
};

// answers to deal with:
// passengers:
// 0: {identifier: 'adults', value: 2, text: '2 - Erwachsene'}
// 1: {identifier: 'children', value: 0, text: '0 - Kinder'}
// 2: {identifier: 'infants', value: 0, text: '0 - Kleinkinder'}
// to
// value: {adults: 2, children: 0, infants: 0}
//
// duration, datesApprox, travelType, budget
// 0: {identifier: 'duration', value: 'twoWeeks', text: '2 Wochen'}
// to
// value: { duration: "twoWeeks" }
//
// highlights:
// 0: {identifier: 'poi', value: 'theGardenRoute', text: 'Garden Route'}
// 1: {identifier: 'poi', value: 'johannesburg', text: 'Johannesburg'}
// 2: {identifier: "preferTalkToAgent", text: "Das bespreche ich lieber mit meinem Reiseexperten – kostenlos", value: true}
// to
// value: { poi: ['theGardenRoute', 'johannesburg'], preferTalkToAgent: true }
