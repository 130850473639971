import { DataGovernance, PollingMode, getClient } from 'configcat-js';

const getConfigCatToken = (): string => {
  const configCatToken = process.env.REACT_APP_CONFIGCAT_TOKEN;

  if (!configCatToken) throw Error('ConfigCat token not present');

  return configCatToken;
};

export const configCatClient = getClient(getConfigCatToken(), PollingMode.AutoPoll, {
  dataGovernance: DataGovernance.EuOnly,
});

export enum FeatureFlags {
  EE_TEST_BEST_TIME_TO_TRAVEL = 'ee_test_besttimetotravel',
  OP_NEW_COPY = 'op_new_copy',
  EE_TEST_NON_OP_FLOW = 'ee_test_non_op_flow',
  EE_TEST_BUDGET_VALUES = 'ee_test_budget_values',
  OP_CO_CR1_IMPROVEMENTS = 'op_co_cr1_improvements',
  HAS_CONFIGCAT_FLAGS = 'has_configcat_flags',
}
